import _ from 'lodash'
import dayjs from 'dayjs'

const commonfunc = {
  methods: {
    // ========================================================================
    // Cookie 관련
    // ========================================================================
    // cookie 생성
    setCookie (name, value, expiredays) {
      const todayDate = new Date()
      todayDate.setDate(todayDate.getDate() + expiredays)
      this.$cookiz.set(name, value, {
        path: '/',
        expires: todayDate
      })
    },

    getCookie (name) {
      return this.$cookiz.get(name)
    },

    // ========================================================================
    // Window 관련
    // ========================================================================
    // 스크롤 비활성화
    scrollStop () {
      $('html').css('overflow', 'hidden')
      $('#element').on('scroll touchmove mousewheel', function (event) { // 터치무브와 마우스휠 스크롤 방지
        event.preventDefault()
        event.stopPropagation()
        return false
      })
    },

    // 스크롤 활성화
    scrollStart () {
      $('html').css('overflow', 'scroll')
      $('#element').off('scroll touchmove mousewheel') // 터치무브 및 마우스휠 스크롤 가능
    },

    scrollToRef (ref) { // 주어진 ref로 스크롤
      if (ref) {
        this.$nextTick(() => {
          if (this.isFunction(ref?.scrollToForm)) {
            ref.scrollToForm()
          } else if (this.isFunction(ref?.scrollIntoView)) {
            ref.scrollIntoView()
          } else if (this.isFunction(ref?.$el?.scrollIntoView)) {
            ref.$el.scrollIntoView()
          }
        })
      }
    },

    /**
     * JavaScript confirm() 기능과 유사
     *
     * @param {String} message 알림창에 출력할 메시지
     * @param {Object} opt 옵션
     * opt = {
     *   okCallback: {Function} // 확인 버튼 클릭시 호출 함수
     *   okParam: {Array} // 확인 버튼 클릭시 호출 함수에 넘겨줄 파라미터 [파라미터1, 파라미터2, ...]
     *   cancelCallback: {Function} // 취소 버튼 클릭시 호출 함수
     *   cancelParam: {Array} // 취소 버튼 클릭시 호출 함수에 넘겨줄 파라미터 [파라미터1, 파라미터2, ...]
     * }
     *
     * @return {Boolean} 확인 버튼 클릭시 true, 취소 버튼 클릭시 false
     */
    async confirm (message, opt) {
      let isOk = false

      await this.$confirm(message, '', {
        confirmButtonText: opt?.yes || '확인',
        cancelButtonText: opt?.no || '취소',
        center: opt?.center || false,
        dangerouslyUseHTMLString: true
      }).then(() => { // 확인 버튼 클릭시
        if (_.isFunction(opt?.okCallback)) {
          opt.okCallback.apply(this, opt?.okParam)
        }
        isOk = true
      }).catch(() => { // 취소 버튼 또는 알림창 밖 클릭시
        if (_.isFunction(opt?.cancelCallback)) {
          opt.cancelCallback.apply(this, opt?.cancelParam)
        }
      })

      return isOk
    },

    // 위 confirm 과 동일한 alert 생성(공통으로 스타일 지정을 위한 공통 함수 생성)
    async alert (message, opt) {
      let isOk = false

      await this.$alert(message, '', {
        confirmButtonText: opt?.yes || '확인',
        center: opt?.center || true,
        dangerouslyUseHTMLString: true,
        callback: () => {
          if (_.isFunction(opt?.okCallback)) {
            opt.okCallback.apply(this, opt?.okParam)
          }
          isOk = true
        }
      })

      return isOk
    },

    // ========================================================================
    // Date 관련
    // ========================================================================
    getNowDate (pattern = 'YYYYMMDD') {
      return dayjs().format(pattern)
    },

    toJsWeekDay (date, pattern = 'YYYYMMDD') { // JavaScript 방식 요일 변환 [0:일, 1:월, ..., 6:토]
      let tempDate

      if (date) {
        if (date.constructor === Date) {
          tempDate = date
        } else if (date.constructor === String) {
          tempDate = dayjs(date, pattern).toDate()
        }
      } else {
        tempDate = new Date()
      }

      return this.getJsWeekDay(tempDate.getDay())
    },

    getJsWeekDay (idx) { // JavaScript 방식 요일 조회 [0:일, 1:월, ..., 6:토]
      return ['일', '월', '화', '수', '목', '금', '토'][idx]
    },

    getJavaWeekDay (idx) { // JAVA 방식 요일 조회 [1:일, 2:월, ..., 7:토]
      return ['', '일', '월', '화', '수', '목', '금', '토'][idx]
    },

    format (date, pattern) {
      return dayjs(date).format(pattern)
    },

    diffDate (fromDate, toDate, unit) {
      const fromDt = dayjs(fromDate)
      const toDt = dayjs(toDate)
      return toDt.diff(fromDt, unit)
    },

    addHours (date, amount, pattern = 'YYYYMMDD') {
      let tempDate

      if (date.constructor === Date) {
        tempDate = new Date(date.getTime())
        tempDate.setHours(tempDate.getHours() + amount)
      } else if (date.constructor === String) {
        tempDate = dayjs(date, pattern).add(amount, 'hour').format(pattern)
      }

      return tempDate
    },

    addDays (date, amount, pattern = 'YYYYMMDD') {
      let tempDate

      if (date.constructor === Date) {
        tempDate = new Date(date.getTime())
        tempDate.setDate(tempDate.getDate() + amount)
      } else if (date.constructor === String) {
        tempDate = dayjs(date, pattern).add(amount, 'day').format(pattern)
      }

      return tempDate
    },

    // ========================================================================
    // store 관련
    // ========================================================================
    formToStore (form, formName = 'form') { // 입력폼 저장 (to store)
      const formData = _.cloneDeep(form)
      delete formData.size
      delete formData.number
      this.$store.commit('form/setForm', { formName, formData })
    },
    storeToForm (form, formName = 'form') { // 입력폼 복원 (from store)
      const formData = this.$store.getters['form/getForm'][formName]
      Object.assign(form, _.cloneDeep(formData))
    },

    // ========================================================================
    // Validate 관련
    // ========================================================================
    isNotNull (obj) {
      return (obj !== null && typeof obj !== 'undefined')
    },

    isNotEmpty (obj) {
      return (obj !== null && typeof obj !== 'undefined' && obj !== '')
    },

    isEmpty (obj) {
      return isNaN(obj) ? _.isEmpty(obj) : false
    },

    isBlank (obj) {
      if (this.isEmpty(obj)) {
        return true
      } else if (typeof obj === 'string' && obj.trim() === '') {
        return true
      }

      return false
    },

    isFunction (value) {
      return _.isFunction(value)
    },

    // 이메일 유효성검사
    emailValidation (obj) {
      const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
      return re.test(obj)
      // return true
    },

    // 휴대폰번호 유효성검사 ex) 01025874589 or 010-2587-4589
    phoneNumberValidation (obj) {
      const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
      return regPhone.test(obj)
    },

    // 생년월일 유효성검사 ex) 19930101 or 1993-01-01
    birthDayValidation (obj) {
      const regBirth = /^(19[0-9][0-9]|20\d{2})-?(0[0-9]|1[0-2])-?(0[1-9]|[1-2][0-9]|3[0-1])$/
      return regBirth.test(obj)
    },

    continuousPwd (pwd) {
      const pwdArr = [...pwd].map(char => char.charCodeAt())
      for (let i = 0; i < pwdArr.length - 3; i++) {
        if (pwdArr[i] === pwdArr[i + 1] && pwdArr[i] === pwdArr[i + 2] && pwdArr[i] === pwdArr[i + 3]) {
          return true
        } else if (pwdArr[i] + 1 === pwdArr[i + 1] && pwdArr[i] + 2 === pwdArr[i + 2] && pwdArr[i] + 3 === pwdArr[i + 3]) {
          return true
        }
      }
      return false
    },

    sameStr (str, pwd) {
      for (let i = 0; i < pwd.length - 3; i++) {
        if (str.includes(pwd.substring(i, i + 4))) {
          return true
        }
      }
      return false
    },
    /**
     * 문자열에서 이모지 제거
     * @param {} str
     * @returns 문자열에 포함된 이모지 제거
     */
    validImoji (str) {
      const emojis = /(?:[\u2700-\u27BF]|(?:\uD83C[\uDDE6-\uDDFF]){2}|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u0023-\u0039]\uFE0F?\u20E3|\u3299|\u3297|\u303D|\u3030|\u24C2|\uD83C[\uDD70-\uDD71]|\uD83C[\uDD7E-\uDD7F]|\uD83C\uDD8E|\uD83C[\uDD91-\uDD9A]|\uD83C[\uDDE6-\uDDFF]|\uD83C[\uDE01-\uDE02]|\uD83C\uDE1A|\uD83C\uDE2F|\uD83C[\uDE32-\uDE3A]|\uD83C[\uDE50-\uDE51]|\u203C|\u2049|[\u25AA-\u25AB]|\u25B6|\u25C0|[\u25FB-\u25FE]|\u00A9|\u00AE|\u2122|\u2139|\uD83C\uDC04|[\u2600-\u26FF]|\u2B05|\u2B06|\u2B07|\u2B1B|\u2B1C|\u2B50|\u2B55|\u231A|\u231B|\u2328|\u23CF|[\u23E9-\u23F3]|[\u23F8-\u23FA]|\uD83C\uDCCF|\u2934|\u2935|[\u2190-\u21FF])/g
      return str.replace(emojis, '')
    },

    /**
     * 주민등록번호 유효성 체크
     * @param ssn1 첫번째 주민등록번호
     * @param ssn2 두번째 주민등록번호
     * @returns 주민등록번호 유효시 0, 첫번째 주민등록번호 무효시 1, 두번째 주민등록번호 무효시 2를 리턴
     */
    checkSsn (ssn1, ssn2, addMsg = '') {
      if (this.isEmpty(ssn1)) {
        return { errPos: 1, msg: addMsg + '주민등록번호를 입력해주세요.' }
      }

      if (this.size(ssn1) !== 6 || isNaN(ssn1) || !ssn1.match(/\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])/g)) {
        return { errPos: 1, msg: '올바른 ' + addMsg + '주민등록번호를 입력해주세요.' }
      }

      if (this.isEmpty(ssn2)) {
        return { errPos: 2, msg: addMsg + '주민등록번호를 입력해주세요.' }
      }

      if (this.size(ssn2) !== 7 || isNaN(ssn2)) {
        return { errPos: 2, msg: '올바른 ' + addMsg + '주민등록번호를 입력해주세요.' }
      }

      const ssn = ssn1 + ssn2
      const ssnArray = []

      // 공식: M = (11 - ((2×A + 3×B + 4×C + 5×D + 6×E + 7×F + 8×G + 9×H + 2×I + 3×J + 4×K + 5×L) % 11)) % 10
      for (let i = 0; i < 13; i++) {
        ssnArray[i] = ssn.substring(i, i + 1)
      }

      const compare = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5]
      let sum = 0
      for (let i = 0; i < 12; i++) {
        sum += (ssnArray[i] * compare[i])
      }

      if (ssn2.substr(0, 1) > 4) { // 외국인 ( 1900년대 5:남자 6:여자  2000년대 7:남자, 8:여자)
        sum = (13 - (sum % 11)) % 10
      } else { // 내국인 ( 1900년대 1:남자 2:여자  2000년대 3:남자, 4:여자)
        sum = (11 - (sum % 11)) % 10
      }

      if (sum !== this.toNumber(ssnArray[12])) {
        return { errPos: 1, msg: '올바른 ' + addMsg + '주민등록번호를 입력해주세요.' }
      }

      return { errPos: 0 }
    },

    // 문자열 email 형식 포함여부
    emailIncdChk (obj) {
      if (this.isNotEmpty(obj.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/))) {
        return true
      }
      return false
    },

    // 문자열 카드번호 형식 포함여부(카드번호,전화번호 같이 체크시 카드번호부터 체크)
    cardIncdChk (obj) {
      if (this.isNotEmpty(obj.match(/\d{4}-\d{4}-\d{4}-\d{4}/))) {
        return true
      }
      return false
    },

    // 문자열 전화번호 형식 포함여부
    phoneIncdChk (obj) {
      if (this.isNotEmpty(obj.match(/\d{2,3}-\d{3,4}-\d{4}/))) {
        return true
      }
      return false
    },

    // 주민등록번호 형식 포함여부
    rrnIncdChk (obj) {
      if (this.isNotEmpty(obj.match(/([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/))) {
        return true
      }
      return false
    },

    // 운전면허번호 형식 포함여부
    driveLicenseIncdChk (obj) {
      if (this.isNotEmpty(obj.match(/\d{2}-\d{6}-\d{3}/))) {
        return true
      }
      return false
    },

    // ========================================================================
    // Array 관련
    // ========================================================================
    getElement (arr, index) {
      return Array.isArray(arr) ? arr[index] : null
    },

    isBlankElement (arr, index) {
      return Array.isArray(arr) ? this.isBlank(arr[index]) : true
    },

    // 주어진 배열의 모든 엘리먼트를 삭제
    removeAll (arr) {
      if (Array.isArray(arr) && arr.length > 0) {
        arr.splice(0) // Vue 감지를 위해 length 대신 arr 이용
      }
    },

    // 주어진 배열의 마지막 엘리먼트를 삭제
    removeLast (arr) {
      if (Array.isArray(arr) && arr.length > 0) {
        arr.splice(arr.length - 1) // Vue 감지를 위해 length 대신 arr 이용
      }
    },

    // 주어진 배열에서 지정된 엘리먼트를 삭제
    removeElement (arr, queryObj) {
      if (Array.isArray(arr) && arr.length > 0) {
        const idx = _.findIndex(arr, queryObj)
        if (idx > -1) {
          arr.splice(idx, 1)
        }
      }
    },

    // 주어진 배열의 모든 엘리먼트에 대해 지정된 속성을 삭제
    removeProperty (arr, propertyName) {
      if (Array.isArray(arr) && (propertyName && typeof propertyName === 'string')) {
        arr.forEach((row) => {
          delete row[propertyName]
        })
      }
    },

    arrayInterSection (arr1, arr2) {
      return arr1.filter(elem => arr2.includes(elem))
    },

    find (arr, queryObj) {
      return _.find(arr, queryObj)
    },

    findIndex (arr, queryObj) {
      return _.findIndex(arr, queryObj)
    },

    // ========================================================================
    // String 관련
    // ========================================================================
    // String 타입이 empty일 때 빈 문자열 반환
    emptyString (value) {
      return this.isNotEmpty(value) ? value : ''
    },

    /**
     * org.apache.commons.lang3.StringUtils.defaultString(String str, String defaultStr) 메소드와 동일
     *
     * @param {string|object} obj 문자열(또는 객체)
     * @param {string} 문자열(또는 객체)가 null 또는 undefined인 경우 반환할 기본 문자열(기본값: '')
     * @return {string} 문자열(또는 객체)가 null 또는 undefined인 경우 기본 문자열, 아니면 문자열(또는 객체)의 문자열
     */
    defaultString (obj, defaultStr = '') {
      if (obj === null || typeof obj === 'undefined') {
        return defaultStr
      }

      return (typeof obj === 'string') ? obj : obj.toString()
    },

    defaultIfBlank (obj, defaultStr = '') {
      return this.isBlank(obj) ? defaultStr : obj
    },

    calStringDbByteLength (str) {
      let b = 0; let i = 0
      let c = ''
      // eslint-disable-next-line no-cond-assign
      for (b = i = 0; c = str.charCodeAt(i++); b += c >> 11 ? 2 : c >> 7 ? 2 : 1) { ; }
      return b
    },

    // 개행문자를 제외한 문자열 여부 확인
    lineBreackChk (str) {
      if (str != null) {
        str = str.replace(/\s/g, '')
        str = str.replace(/\n/g, '')
        str = str.replace(/\r/g, '')
        return (str === '')
      }
      return true
    },

    /**
     * 주어진 문자열에서 숫자가 아닌 문자를 제거한 문자열을 반환
     * org.apache.commons.lang3.StringUtils.getDigits(String str) 메소드와 동일
     * @param {string} str 문자열
     * @return {string} 숫자가 아닌 문자를 제거된 문자열
     */
    getDigits (str) {
      return this.defaultString(str).replace(/[^0-9]/g, '')
    },
    getDigitsWithMinus (str) {
      return this.defaultString(str).replace(/[^-0-9]/g, '')
    },

    getAlpaSpace (str) { // 영문, 한글, 공백, 천지인 키보드(ㆍᆢ)
      return this.defaultString(str).replace(/[^a-z|A-Z|ㄱ-ㅎ|가-힝|ㆍᆢ ]/g, '')
    },

    removeBlank (str) { // 문자열에서 모든 Whitespace 문자를 제거한 문자열을 반환
      return str ? str.replace(/\s+/g, '') : str
    },

    replaceSpcl (str) {
      return str.replace(/&bull;/g, '•')
    },

    replaceHtmlSpcl (str) {
      return str
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/(&quot;)/g, '')
        .replace(/&hearts;/g, '♥')
        .replace(/\\n/g, '<br>')
        .replace(/&lt!;/g, '<!')
        .replace(/(&quot;|\\n)/g, '"')
        .replace(/(&middot;|\\n)/g, '·')
        .replace(/(&bull;|\\n)/g, '•')
        .replace(/&nbsp;/g, ' ')
        .replace(/(&#035;|\\n)/g, '#')
        .replace(/(&#039;|\\n)/g, "'")
        .replace(/(&quot;)/g, '')
        .replace(/&lsquo;/g, '‘')
        .replace(/&rsquo;/g, '’')
        .replace(/&ldquo;/g, '“')
        .replace(/&rdquo;/g, '”')
        .replace(/&zwj;♀️/, '')
        .replace(/&times;/g, '×')
        .replace(/&hellip;/g, '…')
        .replace(/\r/g, '')
    },

    replaceAnotSpcl (str) { // 글 내용중 <! 표기 주석인식 해제
      return str
        .replace(/<!/gi, '&#x003C;&#x0021;')
    },

    unescape (str) {
      return _.unescape(str)
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/(&quot;)/g, '')
        .replace(/&hearts;/g, '♥')
        .replace(/\\n/g, '<br>')
        .replace(/&lt!;/g, '<!')
        .replace(/(&quot;|\\n)/g, '"')
        .replace(/(&middot;|\\n)/g, '·')
        .replace(/(&bull;|\\n)/g, '•')
        .replace(/&nbsp;/g, ' ')
        .replace(/(&#035;|\\n)/g, '#')
        .replace(/(&#039;|\\n)/g, "'")
        .replace(/(&quot;)/g, '')
        .replace(/&lsquo;/g, '‘')
        .replace(/&rsquo;/g, '’')
        .replace(/&ldquo;/g, '“')
        .replace(/&rdquo;/g, '”')
        .replace(/&zwj;♀️/, '')
        .replace(/&times;/g, '×')
        .replace(/&hellip;/g, '…')
    },

    trimToEmpty (obj) {
      return this.defaultString(obj).trim()
    },

    leftPad (str, size = 0, padStr = '') {
      return _.padStart(str, size, padStr)
    },

    toString (value) {
      return _.toString(value)
    },

    /**
     * 주어진 문자열의 개행문자(\n)를 <br/>태그로 변환
     *
     * @param {string} str - 문자열
     * @return {string} 개행문자(\n)가 <br/>태그로 변환된 문자열
     */
    nlToBr (str) {
      return str ? str.replace(/\n/g, '<br>') : str
    },

    // ========================================================================
    // Number 관련
    // ========================================================================
    /**
     * 주어진 숫자를 Number형 숫자으로 변환된 값을 반환 (NaN인 경우 0을 반환)
     * org.apache.commons.lang3.math.NumberUtils.toInt/toFloat/toDouble(str)과 유사
     *
     * @param {number|string} value 숫자(또는 숫자문자열)
     * @param {number} defaultValue 숫자(또는 숫자문자열)가 Number형 숫자로 변환이 안되는 경우 반환할 기본 숫자(기본값: 0)
     * @return {number} Number형 숫자 (Number형 숫자로 변환이 안되는 경우 0)
     */
    toNumber (value, defaultValue = 0) {
      if (value === null || typeof value === 'undefined') {
        return defaultValue
      }

      if (typeof value === 'number') {
        return value
      }

      const numberValue = Number(this.getDigits(value))

      return isNaN(numberValue) ? defaultValue : numberValue
    },

    floor (value, precision = 0) {
      return _.floor(value, precision)
    },

    calculateRadian (x) {
      return (x * Math.PI) / 180
    },

    // ========================================================================
    //  Formatter 관련
    // ========================================================================
    // 마스킹 ex)test -> te**
    maskValue (str) {
      const originStr = str
      let maskingStr = originStr
      maskingStr = originStr
      // if (originStr.length < 3) {
      //   maskingStr = originStr.replace(/(?<=.{1})./gi, '*')
      // } else {
      //   maskingStr = originStr.replace(/.{2}$/gi, '**')
      // }
      if (originStr.length === 1) {
        maskingStr = originStr.replace(originStr, '*')
      } else if (originStr.length === 2) {
        maskingStr = originStr.slice(0, 1) + '*'
      } else if (originStr.length > 5) {
        maskingStr = originStr.slice(0, -2) + '**'
      } else {
        maskingStr = originStr.slice(0, originStr.length - 2) + '**'
      }
      return maskingStr
    },

    // 숫자 콤마 찍기
    numberComma (obj) {
      // return Number(obj + '').toLocaleString('ko-KR')
      return Number(this.getDigits(obj)).toLocaleString('ko-KR')
    },
    numberCommaWithMinus (obj) {
      return Number(this.getDigitsWithMinus(obj)).toLocaleString('ko-KR')
    },

    dateFormatter (o) {
      if (o.value === null) { return }
      return [o.value.substr(0, 4), o.value.substr(4, 2), o.value.substr(6, 2)].join('-') + ' ' + [o.value.substr(8, 2), o.value.substr(10, 2), (o.value.substr(12, 2).length > 0) ? o.value.substr(12, 2) : '00'].join(':')
    },

    dateYYYYMMDDFormatter (o) {
      if (o.value === null) { return }
      return [o.value.substr(0, 4), o.value.substr(4, 2), o.value.substr(6, 2)].join('-')
    },

    dateYYYYMMDDFormatterStr (o) {
      if (o === null) { return }
      return [o.substr(0, 4), o.substr(4, 2), o.substr(6, 2)].join('-')
    },
    dateYYYYMMDDFormatterCommaStr (o) {
      if (o === null) { return }
      return [o.substr(0, 4), o.substr(4, 2), o.substr(6, 2)].join('.')
    },
    phoneNumberFormat (str) {
      if (this.isNotEmpty(str)) {
        str = str.replaceAll('-', '')
        if (str.length === 10) {
          return str.slice(0, 3) + '-' + str.slice(3, 6) + '-' + str.slice(6)
        }
        if (str.length === 11) {
          return str.slice(0, 3) + '-' + str.slice(3, 7) + '-' + str.slice(7)
        } else {
          return ''
        }
      }
      return str
    },

    // 전화 및 핸드폰 하이픈 추가
    phoneNumberFomatter (num) {
      if (this.isNotEmpty(num)) {
        let formatNum = ''
        const tempNum = num.replace(/-/g, '').trim()
        if (tempNum.length === 11) {
          formatNum = tempNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        } else if (tempNum.length === 8) {
          formatNum = tempNum.replace(/(\d{4})(\d{4})/, '$1-$2')
        } else if (tempNum.indexOf('02') === 0) {
          if (tempNum.length === 10) {
            formatNum = tempNum.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
          } else {
            formatNum = tempNum.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
          }
        } else {
          formatNum = tempNum.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        }
        return formatNum
      }
      return num
    },

    // 현금영수증 전용 카드 하이픈 추가
    cardNumberFomatter (num) {
      if (this.isNotEmpty(num)) {
        let formatNum = ''
        const tempNum = num.replace(/-/g, '').trim()
        if (tempNum.length <= 8) {
          formatNum = tempNum.replace(/(\d{4})(\d{1})/, '$1-$2')
        } else if (tempNum.length <= 12) {
          formatNum = tempNum.replace(/(\d{4})(\d{4})(\d{1})/, '$1-$2-$3')
        } else {
          formatNum = tempNum.replace(/(\d{4})(\d{4})(\d{4})(\d{1})/, '$1-$2-$3-$4')
        }
        return formatNum
      }
      return num
    },

    toPhoneFormat (str) {
      const digits = this.getDigits(str)
      const size = digits.length
      if (size > 7) {
        return digits.substr(0, 3) + '-' + digits.substr(3, 4) + '-' + digits.substr(7, size - 7)
      } else if (size > 3) {
        return digits.substr(0, 3) + '-' + digits.substr(3, size - 3)
      }

      return digits
    },

    // ========================================================================
    //  기타 관련
    // ========================================================================
    setDefaultValueIfEmpty (obj, defaultValue) {
      if (_.isEmpty(obj)) {
        obj = defaultValue
      }
    },

    size (collection) {
      return _.size(collection)
    },

    cloneDeep (value) {
      return _.cloneDeep(value)
    },

    copyTo (fromObj, toObj) {
      if (Array.isArray(fromObj) && Array.isArray(toObj)) {
        fromObj.forEach((row) => {
          toObj.push(row)
        })
      } else {
        for (const key of Object.keys(toObj)) {
          const value = fromObj[key]
          if (typeof value !== 'undefined') {
            this.$set(toObj, key, value)
          }
        }
      }
    },

    has (obj, path) {
      return _.has(obj, path)
    },

    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },

    isMobile (userAgent) {
      const ua = userAgent?.toLowerCase() || window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod|android|app_daiso_ios|app_daiso_android/i.test(ua)
    },

    getMdiaSeCd () { // 매체구분코드(CD_00198) 조회 [10:PC, 20:모바일웹, 30:모바일앱]
      const ua = window.navigator.userAgent.toLowerCase()
      if (ua.includes('app_daiso')) {
        return '30'
      }

      return this.isMobile(ua) ? '20' : '10'
    },

    calculateDistanceByGps (p1, p2) {
      // p1 : {lat:x.xxxxx, lng:x.xxxx}, p2 :{lat:x.xxxxx, lng:x.xxxx}
      const earthRadius = 6371 * 1000 // meter
      const deltaLat = this.calculateRadian(p1.lat - p2.lat)
      const deltaLng = this.calculateRadian(p1.lng - p2.lng)
      const p1Lat = this.calculateRadian(p1.lat)
      const p2Lat = this.calculateRadian(p2.lat)

      const haverSine = Math.sin(deltaLat) * Math.sin(deltaLat) + Math.cos(p1Lat) * Math.cos(p2Lat) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2)
      const c = 2 * Math.atan2(Math.sqrt(haverSine), Math.sqrt(1 - haverSine))
      const d = earthRadius * c
      return d
    },

    getSaleType (row) {
      if (row.sleStsCd === '03' && row.soldOutYn === 'Y') {
        return 1 // 임시 품절
      } else if (row.sleStsCd === '03') {
        return 2 // 판매중
      } else if (row.sleStsCd === '05') {
        return 3 // 판매종료
      }
    },

    toProdUnitList (pdList, prodUnitList) {
      this.removeAll(prodUnitList)
      pdList?.forEach((row) => {
        const prodUnit = {
          pdNo: row.pdNo, // 상품번호
          name: row.exhPdNm, // 전시상품명
          thumb: process.env.CDN_BASE_URL + (row.pdImgUrl || '/images/tempLy/goods_img_1.png') + '/dims/optimize/dims/resize/300x400', // 상품이미지
          price: this.toNumber(row.pdPrc), // 상품가격
          soldOut: row.sleStsCd !== '03', // 판매상태코드[01:판매준비, 02:판매예정, 03:판매중, 04:판매중지, 05:판매종료]
          saleType: this.getSaleType(row), // 3가지 구분 처리
          gfYn: row.pdTy === '03' ? 'Y' : 'N', // 상품권인지에 대한 여부
          reviewScore: this.floor(row.avgStscVal, 1), // 평균별점
          reviewNum: this.toNumber(row.revwCnt), // 리뷰카운트
          total: row.totOrQy, // 전체주문수량
          topFlag: [],
          flag: [],
          spbkSeCd: row?.spbkSeCd,
          optPdYn: row.optPdYn,
          htrkCnvMummQy: row.htrkCnvMummQy, // 용차전환 최소수량
          pkupDdcatYn: row.pkupDdcatYn, // 매장픽업 전용여부
          onlyOnlYn: row.onlyOnlYn, // 온라인 전용여부
          adult: row?.buyLmtAgeCd === '03',
          gift: false,
          rank: 0,
          rankFlag: true,
          buyLmtAgeCd: row?.buyLmtAgeCd, // 성인인증필요구분코드
          buyLmtAgeNm: row?.buyLmtAgeNm,
          adltCtfcYn: this.$store.getters['session/getAdltCtfcYn'],
          adltCtfcCd: this.$store.getters['session/getAdltCtfcCd'],
          fdrmOrPsblYn: row.fdrmOrPsblYn, // 정기배송 가능여부
          new: 'N', // new 순위
          best: row.bestYn === 'Y',
          appDdcatPdYn: row.appDdcatPdYn,
          jpQy: row?.jpQy,
          brndNm: row.brndNm
        }
        if (row.pdImgUrl) {
          prodUnit.thumb = process.env.CDN_BASE_URL + row.pdImgUrl + '/dims/optimize/dims/resize/300x400'
        } else {
          prodUnit.thumb = process.env.WEB_BASE_URL + '/images/content/noimage.png'
        }
        if (row.bunYn === 'Y') { // 묶음상품여부
          prodUnit.topFlag.push('묶음상품')
        }

        if (row.pkupDdcatYn === 'Y') { // 픽업전용여부
          prodUnit.topFlag.push('매장픽업 전용')
        }

        if (row.newPdYn === 'Y') { // 신상품여부
          prodUnit.flag.push('신상')
        }

        if (row.onlyOnlYn === 'Y') { // 온라인전용여부
          prodUnit.flag.push('온라인 전용')
        }

        if (row.pdTy === '04') { // goods
          prodUnit.flag.push('goods')
        }

        prodUnitList.push(prodUnit)
      })
    },

    // ========================================================================
    // DatePicker 관련
    // ========================================================================
    datePickerFocus (event) {
      event.$vnode.elm.firstElementChild.addEventListener('keypress', this.datepickerKeyPress)
      event.$vnode.elm.firstElementChild.addEventListener('keyup', this.datepickerKeyup)
    },

    datepickerKeyPress (e) {
      // console.log(e.keyCode)
      if ((e.keyCode < 48 || e.keyCode > 57)) { // e.keyCode !== 45 && hyphen
        e.preventDefault()
      }
    },

    datepickerKeyup (e) {
      let val = e.srcElement.value.replaceAll('-', '')
      val = val.substr(0, 8)
      if (val.length <= 4) {
        e.srcElement.value = val
      } else if (val.length <= 6) {
        e.srcElement.value = [val.substr(0, 4), val.substr(4, 2)].join('-')
      } else {
        e.srcElement.value = [val.substr(0, 4), val.substr(4, 2), val.substr(6, 2)].join('-')
      }
    },
    // loadScrInit 2023.12.04 삭제
    // 화면초기로딩시 조회
    // async loadScrInit (callbackFnc) {
    //   await this.$AxiosBuilder
    //     .params()
    //     .build()
    //     .get('/cmmn/selCmmnCdList')
    //     .then((res) => {
    //       if (this.isNotEmpty(res.data.data)) {
    //         // 화면 정보
    //         const data = res.data.data
    //         const result = {
    //           cmmnCdList: data.cmmnCdList // 공통코드정보
    //         }
    //         callbackFnc(result)
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
    // 공통코드 조회
    async selCmmnCd (grpList) {
      let cmmnCdList
      await this.$AxiosBuilder
        .data({ grpCdList: grpList })
        .build()
        .post('/cmmn/selCmmnCd')
        .then((res) => {
          if (this.isNotEmpty(res.data.data)) {
            const data = res.data.data
            cmmnCdList = data.cmmnCdList // 공통코드정보
          }
        })
        .catch((err) => {
          console.log(err)
        })
      return cmmnCdList
    },
    // cmmnCdList: 공통코드리스트
    // grpCd: 그룹코드
    // attr {}: fstUserFd(첫번째사용자필드), scdUserFd(두번째사용자필드), thdUserFd(세번째사용자필드), fourUserFd(네번째사용자필드), ffthUserFd(다섯번째사용자필드)
    // gubun: 전체,선택 표시(A:전체,S:선택)
    cmmnCdFilter (cmmnCdList, grpCd, attr = {
      fstUserFd: null,
      scdUserFd: null,
      thdUserFd: null,
      fourUserFd: null,
      ffthUserFd: null
    }, gubun) {
      cmmnCdList = cmmnCdList.filter((f) => { return f.grpCd === grpCd }).sort((a, b) => { return a.sortOrdr - b.sortOrdr })
      if (this.isNotEmpty(attr) && this.isNotEmpty(attr.fstUserFd)) { cmmnCdList = cmmnCdList.filter((f) => { return f.fstUserFd === attr.fstUserFd }).sort((a, b) => { return a.sortOrdr - b.sortOrdr }) }
      if (this.isNotEmpty(attr) && this.isNotEmpty(attr.scdUserFd)) { cmmnCdList = cmmnCdList.filter((f) => { return f.scdUserFd === attr.scdUserFd }).sort((a, b) => { return a.sortOrdr - b.sortOrdr }) }
      if (this.isNotEmpty(attr) && this.isNotEmpty(attr.thdUserFd)) { cmmnCdList = cmmnCdList.filter((f) => { return f.thdUserFd === attr.thdUserFd }).sort((a, b) => { return a.sortOrdr - b.sortOrdr }) }
      if (this.isNotEmpty(attr) && this.isNotEmpty(attr.fourUserFd)) { cmmnCdList = cmmnCdList.filter((f) => { return f.fourUserFd === attr.fourUserFd }).sort((a, b) => { return a.sortOrdr - b.sortOrdr }) }
      if (this.isNotEmpty(attr) && this.isNotEmpty(attr.ffthUserFd)) { cmmnCdList = cmmnCdList.filter((f) => { return f.ffthUserFd === attr.ffthUserFd }).sort((a, b) => { return a.sortOrdr - b.sortOrdr }) }
      if (gubun === 'A') {
        cmmnCdList.unshift({ cmmnCd: '', cmmnCdNm: '전체' })
      } else if (gubun === 'S') {
        cmmnCdList.unshift({ cmmnCd: '', cmmnCdNm: '선택' })
      }
      return cmmnCdList
    },
    async selCdMap (grpList, sort) {
      console.log('공통코드 조회::selCdMap(/cmmn/selCdMap)::req', grpList)
      let cdMap
      await this.$AxiosBuilder
        .data({ grpCdList: grpList, sortYn: this.isEmpty(sort) ? 'N' : sort })
        .build()
        .post('/cmmn/selCdMap')
        .then((response) => {
          const res = response.data
          console.log('<<<', res.data)
          cdMap = res.data
        })
        .catch((err) => {
          console.log(err)
        })

      return cdMap
    },

    // ========================================================================
    // 첨부파일 관련
    // ========================================================================
    // 첨부파일 삭제 (del_yn => N 처리)
    atchFileDelete (atchFileId, atchFileNo, callbackFnc) {
      this.$confirm('삭제하시겠습니까?', '', { // MSG_CM_00000011 : 삭제하시겠습니까?
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      }).then(async () => {
        const atchFileData = {}
        atchFileData.atchFileId = atchFileId
        atchFileData.atchFileNo = atchFileNo
        try {
          await this.$AxiosBuilder
            .data(atchFileData)
            .build()
            .post('/common/file-delete')
            .then((res) => {
              if (res.data) {
                this.$alert('정상적으로 삭제되었습니다.')
                callbackFnc(res.data)
              }
            })
        } catch (e) {
          console.log('첨부파일 삭제 중 에러 발생 :>> ', e)
        }
      })
    },
    // 첨부파일 다운로드
    atchFileDown (path) {
      this.$confirm('파일을 다운로드하시겠습니까?', '', {
        confirmButtonText: '확인',
        cancelButtonText: '취소'
      }).then(() => {
        this.getFile(path)
      }).catch(() => {
      })
    },
    // 파일 다운로드
    getFile (filepath) {
      const path = filepath.substr(1, filepath.length)
      this.$AxiosBuilder
        .responseType('arraybuffer')
        .params({ filePath: path })
        .responseType('blob')
        .build()
        .get('/common/file-download')
        .then((res) => {
          console.log(res)
          // console.log(res.headers['content-disposition'].split('filename=')[1])
          let fileName = 'downfile.file'
          const filenameCheck = res.headers['content-disposition'].split(';').filter(str => str.includes('filename'))
          // console.log(filenameCheck)
          if (filenameCheck) { fileName = decodeURI(filenameCheck[0].split('UTF-8\'\'')[1]) }

          const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
          const link = document.createElement('a')
          link.href = url
          link.download = fileName
          link.click()
          window.URL.revokeObjectURL(url)
        })
    },

    // ========================================================================
    // 이미지 리사이징 관련
    // ========================================================================

    // ========================================================================
    // 크기 변경
    // ========================================================================

    /*
     * 1.원본 비율 유지. 변경된/가로 세로 중 더 작은 길이로 서비스
     */

    // 가로 세로 기준으로 더 작은 값으로 resize
    loadResImage (src, width, height) {
      return `${src}/dims/resize/${width}x${height}`
    },

    // 입력값이 원본보다 작은 요청일 경우만 resize 수행
    // 입력값이 원본보다 큰 경우 이미지 그대로, 투명 캔버스로 확장
    loadResIfLesImage (src, width, height) {
      return `${src}/dims/resizec/${width}x${height}`
    },

    // 입력값만큼 resize 한 후, 부족한 여백을 캔버스로 확장
    loadResFrameImage (src, width, height) {
      return `${src}/dims/resizef/${width}x${height}`
    },

    // 입력값이 원본보다 작은 요청일 경우만 resize 수행후, 부족한 여백 캔버스로 확장
    loadResIfLesFrImage (src, width, height) {
      return `${src}/dims/resizemc/${width}x${height}`
    },

    // 가로x세로+X+Y
    loadResCropImage (src, width, height, X, Y) {
      return `${src}/dims/resizecrop/${width}x${height}+${X}+${Y}`
    },

    /*
     * 2.원본비율 무시. 입력 값대로 크기변경
     */
    loadAsResImage (src, width, height) {
      return `${src}/dims/resize/!${width}x${height}`
    },

    /* 3.가로, 세로 기준 리사이즈된 크기가 더 큰 이미지로 서비스
     * 예제)1000 x 667 또는 750 x 500 중 더 큰 이미지로 서비스
     */
    loadMoResImage (src, width, height) {
      return `${src}/dims/resize/^${width}x${height}`
    },

    /* 4.해상도가 입력 값보다 크거나 작을 경우 크기를 변경
     * 예제) 가로가 1000보다 클 경우에만 가로 1000을 기준으로 리사이즈
     */
    loadResolResImage (src, width, height) {
      return `${src}/dims/resize/>${width}x${height}`
    },

    /* 5.가로, 세로 기준 원본 Width, Height 에서 N% 만큼 크기를 변경
     * 예제) 가로를 50% 크기로 줄여서 리사이즈
     */
    loadPerResImage (src, width, height) {
      return `${src}/dims/resize/${width}%${height}`
    },

    // ========================================================================
    // 잘라내기
    // ========================================================================

    // 이미지를 가로x세로 크기만큼 잘라낸다
    // X , Y 값에 따라 기준점이 이동한다. ( 기본값은 0,0 위치 )
    CrpImage (src, width, height, X, Y) {
      return `${src}/dims/crop/${width}x${height}+${X}+${Y}`
    },

    // 이미지를 가로x세로 크기만큼 잘라낸다
    // X , Y 값에 따라 기준점이 이동한다. ( 기본값은 이미지의 정중앙 위치 )
    CrpCenterImage (src, width, height, X, Y) {
      return `${src}/dims/cropcenter/${width}x${height}+${X}+${Y}`
    },

    // 가로, 세로 크기 중에서 작은 값으로 이미지 가운데를 기준으로 crop 한다.
    CrpMinImage (src) {
      return `${src}/dims/cropc/min`
    },

    // 가로, 세로 크기 중에서 큰 값을 기준으로 사용하는데
    // 이 경우 원본 보다 crop 할 영역이 커지거나 같아지기 때문에
    // 성능을 고려하여 원본을 그대로 서비스 한다.
    loadCrpMaxImage (src) {
      return `${src}/dims/cropc/max`
    },

    // ========================================================================
    // 최적화/품질 변경
    // ========================================================================

    // 이미지의 품질을 변환한다(1 ~ 100)
    chgImgQual (src, quality) {
      return `${src}/dims/quality/${quality}`
    },

    // ========================================================================
    // 이미지 포맷 변경
    // ========================================================================
    chgImgFormat (src, format) {
      return `${src}/dims/format/${format}`
    },

    // ========================================================================
    // 색감 변경
    // ========================================================================

    // 이미지를 흑백으로 변경
    chgGrayImage (src) {
      return `${src}/dims/grayscale/true`
    },

    // 이미지 명도를 조절(-100 ~ 100)
    adjBrightImage (src, brightness) {
      return `${src}/dims/brightness/${brightness}`
    },

    // ========================================================================
    // 회전
    // ========================================================================

    // 이미지를 각도만큼 회전(0 ~ 360)
    rotateImage (src, rotate) {
      return `${src}/dims/rotate/${rotate}`
    },

    // 이미지를 수평으로 대칭이동
    flipHoriz (src) {
      return `${src}/dims/flipflop/horizontal`
    },

    // 이미지를 수직으로 대칭이동
    flipVertical (src) {
      return `${src}/dims/flipflop/vertical`
    },

    // CDN 이미지 경로로 교체
    imgUrlMaker (url, resizeInfo = undefined) {
      if (!this.isNotEmpty(url)) {
        return process.env.WEB_BASE_URL + '/images/content/noimage.png'
      } else {
        let result = process.env.CDN_BASE_URL
        // let result = ''
        if (url.charAt(0) !== '/') {
          result = result + '/'
        }
        if (url.search(result) < 0) {
          result = result + url
        } else {
          result = url
        }
        if (resizeInfo) {
          if (result.search(resizeInfo) < 0) {
            result = result + resizeInfo
          }
        }
        return _.unescape(result)
      }
    },

    getMbMngNo () { // 회원관리번호 조회
      return this.$store.getters['session/getMbMngNo']
    },
    /*
     * 페이지이동
     * path : 이동할 페이지 경로
     * param : 넘겨줄 파라미터 Object
     */
    pageMove (path, param) {
      this.$router.push({
        path,
        query: param
      })
    },

    // 위도경도 가져오기
    getGeolocation (geoData) {
      // geolocation은 https, localhost만 가능
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          geoData.strLttd = position.coords.latitude
          geoData.strLitd = position.coords.longitude
          geoData.flag = true
          geoData.geolocationAgrYn = 'Y'
          console.log('위치정보 사용 가능')
          return geoData
        }, (e) => {
          // 아성다이소 좌표로 셋팅
          geoData.strLttd = 37.486592
          geoData.strLitd = 127.0480896
          geoData.flag = false
          geoData.geolocationAgrYn = 'N'
          console.log('위치정보 사용 불가능11')
          return geoData
        })
      } else {
        // 아성다이소 좌표로 셋팅
        geoData.strLttd = 37.486592
        geoData.strLitd = 127.0480896
        geoData.flag = false
        geoData.geolocationAgrYn = 'N'
        console.log('위치정보 사용 불가능22')
        return geoData
      }
      return geoData
    },

    // ========================================================================
    // SNS 공유
    // ========================================================================

    // SNS 별 공유하기
    toSNS (sns, strTitle, strURL) {
      const snsArray = []
      snsArray.facebook = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(strURL)
      snsArray.band = 'http://band.us/plugin/share?body=' + encodeURIComponent(strTitle) + '  ' + encodeURIComponent(strURL) + '&route=' + encodeURIComponent(strURL)
      snsArray.line = 'http://line.me/R/msg/text/?' + encodeURIComponent(strTitle) + ' ' + encodeURIComponent(strURL)
      window.open(snsArray[sns], 'sns', 'resizable=no width=700 height=700')
    },
    // 숫자 표기(만, 억단위로 표시하고 백의 자리에서 반올림)
    displayformatNumber (num) {
      if (typeof num === 'string') {
        num = Number(num)
      }

      if (num < 10000) {
        return num.toLocaleString('ko-KR')
      }

      let str = ''

      // 반올림하여 억/만으로 표시하여 리턴

      if (num < 1000000) {
        let mok = num / 10000
        console.log(mok)
        mok = Math.round(mok * 10) / 10
        console.log(mok)
        str = String(mok) + '만'
      } else {
        str = '99.9+만'
      }

      return str
    },
  }
}

export {
  commonfunc
}
